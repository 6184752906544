import React from "react"
import { graphql } from "gatsby"
import Search from "../components/search/search"
import SEO from "../utils/seo"
import StructuredData from "../utils/structuredData"
import Hero from "../components/hero/hero"
import CMS from "../utils/cms"
import Feefo from "../components/feefo/feefo"
import RegionBlock from "../components/regions/regionBlock"

const orgData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Sweetcombe Cottage Holidays",
    "url": "https://sweetcombecottages.co.uk/",
    "logo": "https://ams3.digitaloceanspaces.com/swe-cms-media/wp-content/uploads/2021/02/15134654/SweetcombeCottageLogo.jpg",
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "01395 512130",
        "contactType": "customer service",
        "areaServed": "GB",
        "availableLanguage": "en"
    },
    "sameAs": [
        "https://www.facebook.com/sweetcombecottages",
        "https://www.twitter.com/SweetcombeC",
        "https://www.instagram.com/sweetcombecottageholidays/",
        "https://www.linkedin.com/company/cornish-holiday-cottages-limited"
    ]
}

const bnbData = {
    "@context": "https://schema.org",
    "@type": "BedAndBreakfast",
    "name": "Sweetcombe Cottage Holidays",
    "image": "https://www.sweetcombecottages.co.uk/static/5712d25d9563f12d4a894467fc54a457/74fd5/Alamy_SidmouthClockTower-center-scaled.jpg",
    "@id": "",
    "url": "https://www.sweetcombecottages.co.uk/",
    "telephone": "01395 512130",
    "priceRange": "100-600",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Leigh Farm, Weston",
        "addressLocality": "Devon",
        "postalCode": "EX10 0PH",
        "addressCountry": "GB"
    },
    "geo": {
        "@type": "GeoCoordinates",
        "latitude": 50.6953942,
        "longitude": -3.1832598
    } ,
    "sameAs": [
        "https://www.facebook.com/sweetcombecottages",
        "https://www.instagram.com/sweetcombecottageholidays/",
        "https://www.twitter.com/SweetcombeC",
        "https://www.linkedin.com/company/cornish-holiday-cottages-limited"
    ] 
}



const Index = ({ data }) => {
  return (
    <>
      <SEO
        title={data.wpPage.seo.title || data.wpPage.title}
        description={
          data.wpPage.seo.metaDesc ||
          data.wpPage.excerpt ||
          data.wpPage.description
        }
        image={data?.wpPage?.featuredImage?.node.uri}
        canonical={data.wpPage.uri}
        article={false}
      />
      <StructuredData dataObject={orgData} />
      <StructuredData dataObject={bnbData} />
      
      {data.wpPage.featuredImage && (
        <Hero
          highlight={data?.wp?.companyDetails?.companyDetails}
          image={
            data.wpPage.featuredImage.node?.localFile?.childImageSharp?.fluid
          }
        >
          <Search showResults={false} />
        </Hero>
      )}

      <Feefo />

      <CMS>{data.wpPage?.content_module_system.modules}</CMS>
      <RegionBlock data={data.wpPage?.regionMapPanel} />
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    wpPage(title: { eq: "Home" }) {
      ...pageFragments
      content_module_system {
        modules {
          ...headerBlockFragmentsPage
          ...contentFragmentsPage
          ...sideBySideFragmentsPage
          ...navigationSliderFragmentsPage
          ...propSelectFragmentsPage
        }
      }
      regionMapPanel {
        content
        titleTitle
        link {
          target
          title
          url
        }
      }
    }

    wp {
      companyDetails {
        companyDetails {
          highlightImage {
            sourceUrl
          }
          highlightLink {
            target
            title
            url
          }
        }
      }
    }
    

  }
`
