import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import { useInView } from 'react-intersection-observer'

const FeefoWrapper = styled.div.attrs({
  className: "feefoWrapper",
})`
  min-height: 10vh;
`

const Wrapper = styled.div`
  ${(props) => props.theme.wrappersContainers.primaryWrapper};
  ${(props) => props.theme.wrappersContainers.primaryContainer};
`

const Feefo = () => {

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  })

  function script() {
    if (typeof window === `undefined`)
      return "";
    return "https://api.feefo.com/api/javascript/sweetcombe-holiday-cottages";
  }


  return (
    <FeefoWrapper
      ref={ref}>
      {inView ? (
        <>
          <Helmet>
            <script
              type="text/javascript"
              src={script()}
              async
            ></script>
          </Helmet>
          <Wrapper>
            <div
              id="feefo-service-review-carousel-widgetId"
              className="feefo-review-carousel-widget-service"
            ></div>
          </Wrapper>
        </>) : null}
    </FeefoWrapper>
  )
}

export default Feefo
