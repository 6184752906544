import React, { useState, useCallback } from 'react'
import { Polygon, InfoBox, GoogleMap, LoadScript } from '@react-google-maps/api'
import { useInView } from 'react-intersection-observer'

import styled from "styled-components"


const MapWrapper = styled.div`

  height: 400px;

  @media ${props => props.theme.mq.sm} {
    height: 580px;
  }

  #region-map{
    height:100%;
    width: 100%;
  }
  
`
const RegionWrapper = styled.div`
`


const MapRegion = ({ apikey, regions, zoom, center }) => {

  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const budleigh = {
    label: "Budleigh Salterton",
    polygon: [
      {lat: 50.626, lng: -3.336},
      {lat: 50.631, lng: -3.317},
      {lat: 50.628, lng: -3.306},
      {lat: 50.636, lng: -3.295},
      {lat: 50.648, lng: -3.285},
      {lat: 50.656, lng: -3.280},
      {lat: 50.662, lng: -3.275},
      {lat: 50.664, lng: -3.268},
      {lat: 50.671, lng: -3.264},
      {lat: 50.675, lng: -3.247},
      {lat: 50.686, lng: -3.256},
      {lat: 50.687, lng: -3.245},
      {lat: 50.692, lng: -3.246},
      {lat: 50.693, lng: -3.249},
      {lat: 50.701, lng: -3.255},
      {lat: 50.704, lng: -3.261},
      {lat: 50.712, lng: -3.266},
      {lat: 50.719, lng: -3.283},
      {lat: 50.718, lng: -3.286},
      {lat: 50.716, lng: -3.286},
      {lat: 50.714, lng: -3.288},
      {lat: 50.717, lng: -3.293},
      {lat: 50.718, lng: -3.302},
      {lat: 50.720, lng: -3.313},
      {lat: 50.723, lng: -3.323},
      {lat: 50.726, lng: -3.328},
      {lat: 50.728, lng: -3.335},
      {lat: 50.709, lng: -3.349},
      {lat: 50.696, lng: -3.349},
      {lat: 50.690, lng: -3.356},
      {lat: 50.685, lng: -3.360},
      {lat: 50.683, lng: -3.370},
      {lat: 50.680, lng: -3.372},
      {lat: 50.679, lng: -3.377},
      {lat: 50.680, lng: -3.382},
      {lat: 50.671, lng: -3.388},
      {lat: 50.664, lng: -3.379},
      {lat: 50.653, lng: -3.385},
      {lat: 50.650, lng: -3.379},
      {lat: 50.649, lng: -3.371},
      {lat: 50.640, lng: -3.359},
      {lat: 50.635, lng: -3.352},
      {lat: 50.634, lng: -3.345},
      {lat: 50.630, lng: -3.339},
      {lat: 50.626, lng: -3.335}
    ]
  }
  const sidmouth = {
    label: "Sidmouth",
    polygon: [
    {lat: 50.675, lng: -3.247},
    {lat: 50.676, lng: -3.242},
    {lat: 50.677, lng: -3.239},
    {lat: 50.678, lng: -3.236},
    {lat: 50.679, lng: -3.231},
    {lat: 50.683, lng: -3.228},
    {lat: 50.691, lng: -3.226},
    {lat: 50.704, lng: -3.216},
    {lat: 50.707, lng: -3.233},
    {lat: 50.701, lng: -3.240},
    {lat: 50.702, lng: -3.255},
    {lat: 50.693, lng: -3.251},
    {lat: 50.692, lng: -3.246},
    {lat: 50.687, lng: -3.245},
    {lat: 50.686, lng: -3.256},
    {lat: 50.675, lng: -3.247},
    ]
  }
  const sidbury = {
    label: "Sidbury & Salcombe",
    polygon: [
      {lat: 50.680, lng: -3.230},
      {lat: 50.682, lng: -3.208},
      {lat: 50.682, lng: -3.203},
      {lat: 50.683, lng: -3.195},
      {lat: 50.685, lng: -3.191},
      {lat: 50.685, lng: -3.171},
      {lat: 50.687, lng: -3.158},
      {lat: 50.712, lng: -3.172},
      {lat: 50.715, lng: -3.165},
      {lat: 50.717, lng: -3.154},
      {lat: 50.722, lng: -3.170},
      {lat: 50.728, lng: -3.176},
      {lat: 50.744, lng: -3.178},
      {lat: 50.752, lng: -3.191},
      {lat: 50.759, lng: -3.210},
      {lat: 50.760, lng: -3.220},
      {lat: 50.755, lng: -3.235},
      {lat: 50.712, lng: -3.265},
      {lat: 50.703, lng: -3.261},
      {lat: 50.702, lng: -3.255},
      {lat: 50.701, lng: -3.240},
      {lat: 50.707, lng: -3.233},
      {lat: 50.704, lng: -3.216},
      {lat: 50.691, lng: -3.229},
      {lat: 50.691, lng: -3.226},
    ]
  }
  const beer = {
    label: "Beer & Branscombe",
    polygon: [
      {lat: 50.686, lng: -3.159},
      {lat: 50.685, lng: -3.146},
      {lat: 50.687, lng: -3.126},
      {lat: 50.686, lng: -3.097},
      {lat: 50.696, lng: -3.093},
      {lat: 50.696, lng: -3.089},
      {lat: 50.699, lng: -3.084},
      {lat: 50.703, lng: -3.081},
      {lat: 50.703, lng: -3.059},
      {lat: 50.728, lng: -3.059},
      {lat: 50.738, lng: -3.067},
      {lat: 50.740, lng: -3.063},
      {lat: 50.743, lng: -3.068},
      {lat: 50.742, lng: -3.071},
      {lat: 50.745, lng: -3.076},
      {lat: 50.746, lng: -3.082},
      {lat: 50.742, lng: -3.086},
      {lat: 50.740, lng: -3.081},
      {lat: 50.733, lng: -3.090},
      {lat: 50.724, lng: -3.110},
      {lat: 50.716, lng: -3.118},
      {lat: 50.713, lng: -3.128},
      {lat: 50.716, lng: -3.154},
      {lat: 50.715, lng: -3.165},
      {lat: 50.711, lng: -3.172},
      {lat: 50.687, lng: -3.158}
    ]
  }
  const [regionList] = useState([budleigh, sidmouth, beer, sidbury])

  center = {lat: 50.703, lng: -3.217}

  const options = {
    fillColor: "#7fa820",
    fillOpacity: .5,
    strokeColor: "white",
    strokeOpacity: .5,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }
  
  const [activeRegion, setActiveRegion] = useState(null)

  const enterRegion = (thisRegion) => {
    // console.log("Entered region.", thisRegion.label)
    options.fillColor = "darkgreen"
    setActiveRegion(thisRegion)
  }
  
  const exitRegion = (thisRegion) => {
    // console.log("Exit region.", thisRegion.label)
    options.fillColor = "#7fa820"
    setActiveRegion(null)
  }
  
  const renderRegions = (regions) => {
    return regions.map((region, i) => (
      <RegionWrapper key={"region" + i} >
        {activeRegion === region &&
        <InfoBox
          key={"info" + (i+1)}
          options={{closeBoxURL: '', enableEventPropagation: true, zIndex: 5}}
          position={region.polygon[0]}
        >
          <div style={{ backgroundColor: 'white', opacity: 0.75, padding: 10 }}>
            <div style={{ fontSize: 12, fontColor: `#08233B` }}>
              {region.label}
            </div>
          </div>
        </InfoBox>
        }

        <Polygon
          key={i+1}
          path={region.polygon}
          options={options}
          onMouseOver={() => { enterRegion(region) }}
          onMouseOut={() => { exitRegion(region) }}
        />
      </RegionWrapper>
      )
    )
  }

  const [, setMap] = useState(null)
 
  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    //map.setCenter(center)
    //map.setZoom(zoom)
    setMap(map)
  }, [])
 
  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])
 
  return (
  <div ref={ref}>
    {inView ? (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={apikey}
    >
      <MapWrapper>
        <GoogleMap
          id="region-map"
          zoom={zoom}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {renderRegions(regionList)}
        </GoogleMap>
      </MapWrapper>
    </LoadScript>) :null}
    </div>
  )

}

export default React.memo(MapRegion)
