import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Heading from "../../utils/heading"
import MapRegion from "../googleMap/mapRegion"

const Wrapper = styled.div.attrs({
    className: "regionWrapper container",
})`
    ${props => props.theme.wrappersContainers.primaryContainer};
    overflow:auto;
    content-visibility: auto;

    .inner{
        ${props => props.theme.wrappersContainers.primaryWrapper};

        .contentWrapper{
            display: grid;
            grid-gap: 1rem;
            grid-template-rows: auto auto;
            grid-template-columns: 1fr;
            width: 100%;
            grid-template-areas:
                    "text"
                    "map";

            @media ${props => props.theme.mq.sm} {
                grid-gap: 3rem;
                grid-template-rows: auto;
            }

            @media ${props => props.theme.mq.lg} {
                grid-template-areas:
                        "text map";
                grid-template-columns: 1fr 1fr;
                grid-gap: 5rem;
            }

            .text {
                grid-area: text;
            }

            .mapRegionWrapper {
                grid-area: map;
            }

            .linkButton{
                ${props => props.theme.buttons.primary};
                margin-top:1rem;
            }

            .title{
                margin:0 0 1rem 0;
                @media ${props => props.theme.mq.md} {
                    margin:0 0 2rem 0;
                }
            }
    
            .subTitle{
                margin:0 0 1rem 0;
                @media ${props => props.theme.mq.md} {
                    margin:0 0 2rem 0;
                }
            }
    
        }

    }

`

const RegionBlock = props => {

    return (

        <Wrapper>
            <div className="inner">

                {props.data.content &&
                    <div className={"contentWrapper"}>

                        <div className={"text"}>

                            {props.data.titleTitle &&
                                <Heading type="title" as={"h3"} color={props => props.theme.colors.secondary}>
                                    {props.data.titleTitle}
                                </Heading>
                            }
                            <div dangerouslySetInnerHTML={{ __html: props.data.content }} />

                            {props.data.link &&
                                <Link className="linkButton" to={props.data.link.url.replace(process.env.GATSBY_CMS_ADDR, '')} title={props.data.link.title} target={props.data.link.target}>
                                    {props.data.link.title}
                                </Link>
                            }

                        </div>

                        <div className={"mapRegionWrapper"}>
                            <MapRegion
                                key={"mapregion"}
                                apikey={process.env.GATSBY_MAPS_KEY}
                                regions={[]}
                                zoom={11}
                                center={{ lat: 50.703, lng: -3.217 }}
                            />
                        </div>

                    </div>
                }

            </div>
        </Wrapper>

    )

}

export default RegionBlock